import * as React from "react";
import "./Message.scss";

import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const Message = () => {
  return (
    <section className="message-content">
      <div className="status-modal-container">
        <StaticImage
          src={"../../images/illustrations/success-illustration.svg"}
          className="status-modal-illustration"
          alt="ícono de éxito"
          loading="lazy"
        />
        <p className="thanks">¡Gracias por tu mensaje!</p>
        <p className="text">
          Un representante nuestro se comunicará en las próximas
          horas.
        </p>
      </div>
      <div className="return-button">
        <Link to="/" offset={50}>
          <button type="button">Volver</button>
        </Link>
      </div>
    </section>
  );
};

export default Message;
